/*
 * Copyright 2019 General Code
 */

.isSticky {
  z-index: 10;
  padding: 0;
}

.isNotSticky {
  transform: none !important;
}

body.no-header #menubarContainer {
  display: none !important;
}

html.translated-ltr, body.has-warning, body.versioned, body.menubar-is-sticky {
  position: relative;
}

body.has-warning #warning, body.versioned #versionWarning, body.menubar-is-sticky #menubarContainer, body.menubar-is-sticky #drawerContainer, body.menubar-is-sticky #drawerArea {
  position: fixed;
  margin-top: 0;
  top: 0 !important;
}

html.translated-ltr body, html.translated-ltr body.has-warning #warning, html.translated-ltr body.versioned #versionWarning, html.translated-ltr body #menubarContainer, html.translated-ltr body.menubar-is-sticky #drawerContainer, html.translated-ltr body.menubar-is-sticky #drawerArea {
  top: 40px !important;
}

body.has-warning, body.has-warning.versioned #versionWarning, body.has-warning #menubarContainer, body.has-warning.menubar-is-sticky #drawerContainer, body.has-warning.menubar-is-sticky #drawerArea {
  top: 42px !important;
}

body.versioned, body.versioned #menubarContainer, body.versioned.menubar-is-sticky #drawerContainer, body.versioned.menubar-is-sticky #drawerArea {
  top: 48px !important;
}

html.translated-ltr body.has-warning, html.translated-ltr body.has-warning.versioned #versionWarning, html.translated-ltr body.has-warning #menubarContainer, html.translated-ltr body.has-warning.menubar-is-sticky #drawerContainer, html.translated-ltr body.has-warning.menubar-is-sticky #drawerArea {
  top: 82px !important;
}

html.translated-ltr body.versioned, html.translated-ltr body.versioned #menubarContainer, html.translated-ltr body.versioned.menubar-is-sticky #drawerContainer, html.translated-ltr body.versioned.menubar-is-sticky #drawerArea {
  top: 88px !important;
}

body.has-warning.versioned, body.has-warning.versioned #menubarContainer, body.has-warning.versioned.menubar-is-sticky #drawerContainer, body.has-warning.versioned.menubar-is-sticky #drawerArea {
  top: 90px !important;
}

html.translated-ltr body.has-warning.versioned, html.translated-ltr body.has-warning.versioned #menubarContainer, html.translated-ltr body.has-warning.versioned.menubar-is-sticky #drawerContainer, html.translated-ltr body.has-warning.versioned.menubar-is-sticky #drawerArea {
  top: 130px !important;
}

@media (max-width: 995px) and (min-width: 760px) {

  body.responsive.menubar-is-sticky .sidebarArea {
    top: 41px !important;
  }

  html.translated-ltr body.responsive.menubar-is-sticky .sidebarArea {
    top: 82px !important;
  }

  body.responsive.has-warning.menubar-is-sticky .sidebarArea {
    top: 84px !important;
  }

  body.responsive.versioned.menubar-is-sticky .sidebarArea {
    top: 90px !important;
  }

  html.translated-ltr body.responsive.has-warning.menubar-is-sticky .sidebarArea {
    top: 124px !important;
  }

  html.translated-ltr body.responsive.versioned.menubar-is-sticky .sidebarArea {
    top: 130px !important;
  }

  body.responsive.has-warning.versioned.menubar-is-sticky .sidebarArea {
    top: 132px !important;
  }

  html.translated-ltr body.responsive.has-warning.versioned.menubar-is-sticky .sidebarArea {
    top: 172px !important;
  }
}

@media (max-width: 759px) {

  body.responsive, html.translated-ltr body.responsive, html.translated-ltr body.responsive.has-warning #warning, body.responsive.has-warning #warning, body.responsive.versioned #versionWarning, html.translated-ltr body.responsive.versioned #versionWarning, body.responsive #menubarContainer, html.translated-ltr body.responsive #menubarContainer, body.responsive #drawerContainer, html.translated-ltr body.responsive #drawerContainer, body.responsive #drawerArea, html.translated-ltr body.responsive #drawerArea {
    top: 31px !important;
  }

  body.no-header.responsive, html.translated-ltr body.no-header.responsive, html.translated-ltr body.no-header.responsive.has-warning #warning, body.no-header.responsive.has-warning #warning, body.no-header.responsive.versioned #versionWarning, html.translated-ltr body.no-header.responsive.versioned #versionWarning, body.no-header.responsive #menubarContainer, html.translated-ltr body.no-header.responsive #menubarContainer, body.no-header.responsive #drawerContainer, html.translated-ltr body.no-header.responsive #drawerContainer, body.no-header.responsive #drawerArea, html.translated-ltr body.no-header.responsive #drawerArea {
    top: 0 !important;
  }

  body.responsive.has-warning #menubarContainer, body.responsive.has-warning #drawerContainer, body.responsive.has-warning #drawerArea {
    top: 71px !important;
  }

  body.no-header.responsive.has-warning #menubarContainer, body.no-header.responsive.has-warning #drawerContainer, body.no-header.responsive.has-warning #drawerArea {
    top: 40px !important;
  }

  body.responsive.has-warning, body.responsive.has-warning.versioned #versionWarning {
    top: 73px !important;
  }

  body.no-header.responsive.has-warning, body.no-header.responsive.has-warning.versioned #versionWarning {
    top: 42px !important;
  }

  body.responsive.versioned, body.responsive.versioned #menubarContainer, body.responsive.versioned #drawerContainer, body.responsive.versioned #drawerArea {
    top: 79px !important;
  }

  body.no-header.responsive.versioned, body.no-header.responsive.versioned #menubarContainer, body.no-header.responsive.versioned #drawerContainer, body.no-header.responsive.versioned #drawerArea {
    top: 48px !important;
  }

  html.translated-ltr body.responsive.has-warning #menubarContainer, html.translated-ltr body.responsive.has-warning #drawerContainer, html.translated-ltr body.responsive.has-warning #drawerArea {
    top: 71px !important;
  }

  html.translated-ltr body.no-header.responsive.has-warning #menubarContainer, html.translated-ltr body.no-header.responsive.has-warning #drawerContainer, html.translated-ltr body.no-header.responsive.has-warning #drawerArea {
    top: 40px !important;
  }

  html.translated-ltr body.responsive.has-warning, html.translated-ltr body.responsive.has-warning.versioned #versionWarning {
    top: 73px !important;
  }

  html.translated-ltr body.no-header.responsive.has-warning, html.translated-ltr body.no-header.responsive.has-warning.versioned #versionWarning {
    top: 42px !important;
  }

  html.translated-ltr body.responsive.versioned, html.translated-ltr body.responsive.versioned #menubarContainer, html.translated-ltr body.responsive.versioned #drawerContainer, html.translated-ltr body.responsive.versioned #drawerArea {
    top: 79px !important;
  }

  html.translated-ltr body.no-header.responsive.versioned, html.translated-ltr body.no-header.responsive.versioned #menubarContainer, html.translated-ltr body.no-header.responsive.versioned #drawerContainer, html.translated-ltr body.no-header.responsive.versioned #drawerArea {
    top: 48px !important;
  }

  body.responsive.has-warning.versioned, body.responsive.has-warning.versioned #menubarContainer, body.responsive.has-warning.versioned #drawerContainer, body.responsive.has-warning.versioned #drawerArea {
    top: 121px !important;
  }

  body.no-header.responsive.has-warning.versioned, body.no-header.responsive.has-warning.versioned #menubarContainer, body.no-header.responsive.has-warning.versioned #drawerContainer, body.no-header.responsive.has-warning.versioned #drawerArea {
    top: 90px !important;
  }

  html.translated-ltr body.responsive.has-warning.versioned, html.translated-ltr body.responsive.has-warning.versioned #menubarContainer, html.translated-ltr body.responsive.has-warning.versioned #drawerContainer, html.translated-ltr body.responsive.has-warning.versioned #drawerArea {
    top: 121px !important;
  }

  html.translated-ltr body.no-header.responsive.has-warning.versioned, html.translated-ltr body.no-header.responsive.has-warning.versioned #menubarContainer, html.translated-ltr body.no-header.responsive.has-warning.versioned #drawerContainer, html.translated-ltr body.no-header.responsive.has-warning.versioned #drawerArea {
    top: 90px !important;
  }

  body.responsive .sidebarArea, html.translated-ltr body.responsive .sidebarArea {
    top: 112px !important;
  }

  body.no-header.responsive .sidebarArea, html.translated-ltr body.no-header.responsive .sidebarArea {
    top: 81px !important;
  }

  body.responsive.has-warning .sidebarArea {
    top: 152px !important;
  }

  body.no-header.responsive.has-warning .sidebarArea {
    top: 121px !important;
  }

  body.responsive.versioned .sidebarArea {
    top: 160px !important;
  }

  body.no-header.responsive.versioned .sidebarArea {
    top: 129px !important;
  }

  html.translated-ltr body.responsive.has-warning .sidebarArea {
    top: 152px !important;
  }

  html.translated-ltr body.no-header.responsive.has-warning .sidebarArea {
    top: 121px !important;
  }

  html.translated-ltr body.responsive.versioned .sidebarArea {
    top: 160px !important;
  }

  html.translated-ltr body.no-header.responsive.versioned .sidebarArea {
    top: 129px !important;
  }

  body.responsive.has-warning.versioned .sidebarArea {
    top: 202px !important;
  }

  body.no-header.responsive.has-warning.versioned .sidebarArea {
    top: 171px !important;
  }

  html.translated-ltr body.responsive.has-warning.versioned .sidebarArea {
    top: 202px !important;
  }

  html.translated-ltr body.no-header.responsive.has-warning.versioned .sidebarArea {
    top: 171px !important;
  }
}

@media print {
  html.translated-ltr body, body.has-warning, body.versioned, body.menubar-is-sticky, html.translated-ltr body.has-warning, html.translated-ltr body.versioned, html.translated-ltr body.menubar-is-sticky, body.has-warning.versioned, body.has-warning.menubar-is-sticky, body.versioned.menubar-is-sticky, html.translated-ltr body.has-warning.versioned, html.translated-ltr body.has-warning.menubar-is-sticky, html.translated-ltr body.versioned.menubar-is-sticky, body.has-warning.versioned.menubar-is-sticky, html.translated-ltr body.has-warning.versioned.menubar-is-sticky {
    top: 0 !important;
  }

  body.has-warning #warning, body.versioned #versionWarning, body.menubar-is-sticky #menubarContainer, body.menubar-is-sticky #drawerContainer, body.menubar-is-sticky #drawerArea {
    position: static;
  }
}
